import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { Button, Form, FormGroup, Label, Input, Media} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import 'react-toastify/dist/ReactToastify.css';

const ConversionPanel = () => {
    const fileInputRef = useRef(null);
    const interneSelectRef = useRef(null);
    const manualEntryRef = useRef(null);
    const [manualNumber, setManualNumber] = useState("");
    const [manualEntry, setManualEntry] = useState(false);
    const dateSelectRef = useRef(null);
    const [totalHours, setTotalHours] = useState(0);
    const [chartData, setChartData] = useState([]);

    const handleSelectChange = (e) => {
        if (e.target.value === 'manual') {
            setManualEntry(true);
            setManualNumber("");
        } else {
            setManualEntry(false);
            setManualNumber(e.target.value);
        }
    };

    const processCSV = (str, delim = ',') => {
        return str.split('\n').map((row) => row.split(delim));
    };

    const formatDateForExcel = (dateString) => {
        const parts = dateString.split('.');
        return `${parts[2]}.${parts[1]}.${parts[0]}`;
    };

    const convertToXLS = () => {
        const file = fileInputRef.current.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const csvData = XLSX.utils.sheet_to_csv(worksheet);
            const rows = processCSV(csvData);

            const projectHours = {};
            const socleProjects = [130707, 130707, 130386, 130390, 130395, 130458, 130398, 130174, 114454, 125225, 124916, 124925, 126590, 125229, 125231, 125226, 125224, 125230, 124922, 124908, 124906, 124887, 132019, 131892, 131498, 131847, 130726, 130721, 130885, 131268, 130727, 130725, 130724, 130723, 130722, 130397, 130396];

            rows.forEach((row, index) => {
                // Ignore l'en-tête
                if (index === 0)
                    return;

                const [project, start, end, comment] = row;

                // Ignore la ligne si le projet n'est pas au bon format ou si les heures de début ou de fin sont manquantes
                if (!project.match(/^\d+\s*-\s*.+$/)|| !start || !end)
                    return;

                const duration = moment(end, 'HH:mm:ss').diff(moment(start, 'HH:mm:ss'), 'hours', true);
                const projectNumber = parseInt(project.split('-')[0]);

                if (socleProjects.includes(projectNumber)) {
                    const key = `${project} # ${comment || ''}`.trim();
                    console.log(key, duration);
                    projectHours[key] = (projectHours[key] || 0) + duration;
                }
                else {
                    projectHours[project] = (projectHours[project] || 0) + duration;
                }


            });

            const xlsRows = Object.entries(projectHours).map(([key, hours]) => {
                const [project, comment] = key.split(' # ');
                const numberSagex = project.split('-')[0];
                return {
                    'N° école': '1',
                    'N° Interne Collaborateur': manualNumber,
                    'N° projet': numberSagex, // Remove quotes from project number
                    'N° activité': '29',
                    'Heures':'',
                    'Heures (en centième)': hours,
                    'Date (jj.mm.aaaa)': formatDateForExcel(dateSelectRef.current.value),
                    'Commentaire': comment || '',
                    'I_PERSONNE': ''
                };
            });

            const newWorkbook = XLSX.utils.book_new();
            const newWorksheet = XLSX.utils.json_to_sheet(xlsRows);
            XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, 'Sheet1');

            XLSX.writeFile(newWorkbook, `exportForSagex_${moment().format('YYYYMMDDHHmm')}.xls`);

            setTotalHours(xlsRows.reduce((acc, curr) => acc + curr['Heures (en centième)'], 0));
            toast.success("Export effectué avec succès, passer à l'étape 4", {
                position: "bottom-center"
            });

            const formattedChartData = Object.entries(projectHours).map(([key, value]) => ({
                name: key, // Nom du projet
                heures: value, // Total des heures
            }));

            setChartData(formattedChartData);

        };

        reader.readAsBinaryString(file);
    };

    return (
        <div>
        <Form>
            <FormGroup>
                <Label for="fileBrowser">1. Importer le fichier CSV exporté de votre agenda</Label>
                <Input type="file" id="fileBrowser" label="Choisir un fichier" innerRef={fileInputRef} accept=".csv" />
            </FormGroup>
            <FormGroup>
                <Label for="collaboratorSelect">2. Qui êtes-vous ?</Label>
                <>
                    <Input type="text" placeholder="Entrez votre numéro de collaborateur"  value={manualNumber} onChange={(e) => setManualNumber(e.target.value)} />
                    <FormGroup>
                        <Label for="helpImage">Vous n° de collabotateur se trouve dans les préférences de SageX :</Label>
                        <br />
                        <Media object src="/img/formNumeroInterne.png" alt="Localisation du numéro de collaborateur" style={{ width: '300px', height: 'auto' }}/>
                    </FormGroup>
                </>

            </FormGroup>
            <FormGroup>
                <Label for="dateSelect">3. Choisir la date d'export</Label>
                <Input type="select" id="dateSelect" innerRef={dateSelectRef}>
                    <option value="31.10.2024">31.10.2024</option>
                    <option value="30.11.2024">30.11.2024</option>
                    <option value="31.12.2024">31.12.2024</option>
                    <option value="31.01.2025">31.01.2025</option>
                    <option value="28.02.2025">28.02.2025</option>
                    <option value="31.03.2025">31.03.2025</option>
                    <option value="30.04.2025">30.04.2025</option>
                    <option value="31.05.2025">31.05.2025</option>
                    <option value="30.06.2025">30.06.2025</option>
                </Input>
            </FormGroup>

            <Button color="primary" onClick={convertToXLS}>Exporter le fichier XLS pour SageX</Button>
            <div>Total des heures : {totalHours.toFixed(2)} heures</div>
        </Form>
            {chartData.length > 0 && (
                <BarChart
                    width={1350}
                    height={600}
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 0,
                        left: 260,
                        bottom: 220,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" angle={-20} textAnchor="end" height={60} />
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" align="center" wrapperStyle={{ lineHeight: '40px' }} />
                    <Bar dataKey="heures" fill="#82ca9d" barSize={80} />
                </BarChart>
            )}
        <ToastContainer />

    </div>
    );
};

export default ConversionPanel;
